.loading{
    margin: 60px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-verification{
        display: flex;
        gap: 25px;
        margin-top: 50px; 
        margin-bottom: 40px;
        align-items: center;
    }
}

 

.table-wrapper{
    overflow-x: auto;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    white-space: nowrap;
}

.stepper{
    &-mv{
        display: none !important;
    }
}

.nodots{
    list-style-type: none;
    padding: 0;
}

.header{
    &-out{
        &-nav{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 20px 5%;
            &-login{
                max-width: 600px;
                margin: auto;
                margin-bottom: 10px;
            }
            &-flex{
                display: flex;
                align-items: center;
            }
            &-arrow{
                cursor: pointer;
                border-radius: 50%;
                padding: 6px;
                color: #03989E;
                &:hover{
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }
            &-title{
                display: inline-block;
                margin-left: 10px;
                font-size: 20px;
                color: #03989E;
            }
        }
    }
    &-bar{
        .MuiToolbar-root{
            padding-left: 6%;
            padding-right: 6%;
        }
    }
}

//Estilos header
.toolbar{
    background: linear-gradient(90deg, rgba(255,255,255,1) 25%, rgba(128,198,6,1) 75%);
    height: 160px;
    display: flex !important;
    justify-content: space-between;
    align-items: center !important;
    &-left{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        height: 120px;
    }
    &-right{
        color: #7A7A7A;
        font-family: "Roboto";
        font-weight: 400;
        font-style: italic;
        &-images{
            padding-left: 20px;
            margin-top: 10px;
        }
        img{
            max-height: 100px;
        }
    }
    img {
        height: 100%;
        width: auto;
      }
    &-breadcrumbs{
        display: flex;
        align-items: center;
        span{
            font-weight: 700;
            font-size: 18px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
    }
}

.toolbarsnd{
    background-color: #005A81;
    height: 60px;
    &-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }
}

.maquinaria{
    display: flex;
    flex-direction: column;
    &-imagenes{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
    }
    &-imagen{
        filter: contrast(0.5);
        max-height: 250px;
        width: 100%;
        border: 2px solid transparent;
        &-container{
            cursor: pointer;
        }
        &.selected{
            border: 2px solid rgb(0, 174, 255);
        }
    }
    &-title{
        margin: 10px;
        font-weight: 500;
    }
}

//Estilos Footer
.footer{
    display:flex;
    flex-direction: column;
    &-container{
        border-top: 1px solid #CBCBCB;
        border-bottom: 1px solid #CBCBCB;
    }
    &-feader{
        margin-right: 6%;
        margin-left: 6%;
        padding: 20px 0;
        text-align: center;
        a{
            color: #c36;
            text-decoration: underline;
            text-decoration-color: #61CE70;
            &:hover{
                color: #336;
            }
        }
    }
    &-text{
        text-align: center;
        background-color: #f4f4f4;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 80px;
        padding-top: 15px;
        color: #7A7A7A;
        &-container{
            margin-right: 6%;
            margin-left: 6%;
            a{
                text-decoration: none;
                padding: 5px;
                color: #7A7A7A;
            }
        }
    }
    &-box{
        box-shadow: none !important; 
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        width: 100% !important;
        margin-left: 0 !important;
        flex-wrap: nowrap !important;
    }
    &-imageContainer{
        width: 100%;
        padding-left: 0 !important;
        padding-top: 0 !important;
        flex-basis: auto !important;
        max-width: 100% !important;
        &.maxWidthImport{
            max-width: 270px !important;
        }
    }
    &-imageWrapper{
        padding: 30px;
        box-shadow: none !important;
        box-sizing: border-box;
        background-color: transparent !important;
    }
    &-image{
        width: 100%;
        vertical-align: middle;       
    }
}

//Estilos Menu Lateral
.menulateral{
    &-head{
        background: white;
        background-repeat: no-repeat;
        padding: 25px 5% 40px;
        height: 120px;
        h2{
            margin-bottom: 20px;
        }
        &-section{
            border-bottom: 1px solid #CBCBCB;
            padding: 20px;
            color: #80C606;
            font-size: 18px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 600;
            margin: 0;
        }
        h2,h3{
            margin: 0;
            color: #fff;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
    }
    &-list{
        list-style-type: none;
        padding: 0;
        li{
            align-items: center;
            justify-content: center;
            display: flex;
            min-height: 40px;
            margin: 18px 15px;
            padding-left: 15px;
            padding-right: 15px;
            &.active{              
                color: #fff;
                background-color: #80C606; 
                border-radius: 0.25rem;  
                a, span{
                    color: #fff;
                }                    
            }   
            &:hover{
                background-color: #80C606;               
                border-radius: 0.25rem;
                a, span{
                    color: #fff;
                }
            }
            
            a, span{
                align-items: center;
                background-position: 0;
                background-repeat: no-repeat;
                display: flex;
                height: 100%;
                padding-left: 35px;
                color: #828282;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                text-decoration: none;
                width: 100%;
                cursor: pointer;
                
            }

            

            .principal{
                background-image: url(../../assets/lateralmenu/PantallaPrincipal.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/PantallaPrincipal.png);
                }
            }
            .nuevaUnidad{
                background-image: url(../../assets/lateralmenu/NuevaUnidadProductiva.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/NuevaUnidadProductiva.png);
                }
            }
            .nuevaHA{
                background-image: url(../../assets/lateralmenu/NuevaHuellaAgua.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/NuevaHuellaAgua.png);
                }
            }
            .nuevaBIO{
                background-image: url(../../assets/lateralmenu/AñadirBiodiversidad.png);
                background-size: 20px;
                min-height: 40px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/AñadirBiodiversidad.png);
                }
            }
            .añadirRM{
                background-image: url(../../assets/lateralmenu/AñadirRiegoManual.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/AñadirRiegoManual.png);
                }
            }
            .unidades{
                background-image: url(../../assets/lateralmenu/MisUnidades.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/MisUnidades.png);
                }
            }
            .PR{
                background-image: url(../../assets/lateralmenu/ProgramacióndeRIego.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/ProgramacióndeRIego.png);
                }
            }
            .HA{
                background-image: url(../../assets/lateralmenu/HuelladeAgua.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/HuelladeAgua.png);
                }
            }
            .BIO{
                background-image: url(../../assets/lateralmenu/Biodiversidad.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/Biodiversidad.png);
                }
            }
            .infoClima{
                background-image: url(../../assets/lateralmenu/InfoClimatica.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/InfoClimatica.png);
                }
            }
            .consultasAvanzadas{
                background-image: url(../../assets/lateralmenu/Cosultasavanzadas.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/Cosultasavanzadas.png);
                }
            }
            .usuario{
                background-image: url(../../assets/lateralmenu/Miusuario.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/Miusuario.png);
                }
            }
            .closeicon{
                background-image: url(../../assets/lateralmenu/CerrarSesion.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/CerrarSesion.png);
                }
            }
            .downloadicon{
                background-image: url(../../assets/lateralmenu/downloadicon.svg);
                background-size: 26px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/downloadicon.svg);
                }
            }
        }
        .subNuevaBIO{
            li{
                padding: 0;
                margin: 5px 0;
            }
            a{
                color:#828282;
                &:hover{
                    color:#fff;
                }
            }
        }
    }
    .MuiPaper-root{
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #c1c1c1;
            &:hover{
                background-color: #a8a8a8;
            }
        }
    }
}

.lateralnav{
    &-container{
        width: 40%;
        max-width: 400px;
        padding: 20px 30px;
        box-sizing: border-box;
        background-color: #F8F8F8;
        border-right: 1px solid #CBCBCB;
        overflow-y: scroll;
        &::-webkit-scrollbar{
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #c1c1c1;
            &:hover{
                background-color: #a8a8a8;
            }
        }
    }
    &-head{
        padding-bottom: 30px;
        h2{
            font-size: 20px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 700;
            margin: 0;
        }
        &-section{
            border-bottom: 1px solid #CBCBCB;
            padding: 20px 0;
            color: #80C606;
            font-size: 18px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 600;
            margin: 0;
        }
    }
    &-list{
        margin: 30px 0;
        list-style: none;
        padding: 0;
        li{
            margin: 15px 0 15px 0;
            align-items: center;
            justify-content: center;
            display: flex;
            min-height: 40px;
            cursor: pointer;
            &.active{              
                color: #fff;
                background-color: #80C606; 
                border-radius: 10px;  
                a, span{
                    color: #fff;
                }                    
            }   

            &:hover{
                background-color: #80C606;
                border-radius: 10px;                
                a{
                    color: #F8F8F8;
                    &.misfincas{
                        background-image: url(../../assets/lateralmenu/misfincas_hover.svg);
                    }
                    &.sun{
                        background-image: url(../../assets/lateralmenu/sun_hover.svg);
                    }
                    &.drop{
                        background-image: url(../../assets/lateralmenu/drop_hover.svg);
                    }
                    &.closeicon{
                        background-image: url(../../assets/lateralmenu/cerrar_hover.svg);
                    }
                    &.downloadicon{
                        background-image: url(../../assets/lateralmenu/downloadicon_hover.svg);
                    }
                }

                span{
                    color: #F8F8F8;
                }
            }
            a, span{
                text-decoration: none;
                width: 100%;
                line-height: 22px;
                padding: 14px 15px 14px 35px;
                color: #828282;
                font-weight: 700;
                font-size: 18px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                box-sizing: border-box;
                cursor: pointer;
            }
            .principal, .nuevaUnidad, .nuevaHA, .nuevaBIO, .añadirRM, .unidades, .PR, .HA, .BIO, .infoClima, .consultasAvanzadas, .usuario, .closeicon{
                background-repeat: no-repeat;
                height: 100%;
                background-position: left;
                display: flex;
                align-items: center;
                background-position: 7px;
            }
            .downloadicon{
                background-repeat: no-repeat;
                height: 100%;
                background-position: left;
                display: flex;
                align-items: center;
                background-position: 2px;
            }
            .principal{
                background-image: url(../../assets/lateralmenu/PantallaPrincipal.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/PantallaPrincipal.png);
                }
            }
            .nuevaUnidad{
                background-image: url(../../assets/lateralmenu/NuevaUnidadProductiva.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/NuevaUnidadProductiva.png);
                }
            }
            .nuevaHA{
                background-image: url(../../assets/lateralmenu/NuevaHuellaAgua.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/NuevaHuellaAgua.png);
                }
            }
            .nuevaBIO{
                background-image: url(../../assets/lateralmenu/AñadirBiodiversidad.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/AñadirBiodiversidad.png);
                }
            }
            .añadirRM{
                background-image: url(../../assets/lateralmenu/AñadirRiegoManual.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/AñadirRiegoManual.png);
                }
            }
            .unidades{
                background-image: url(../../assets/lateralmenu/MisUnidades.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/MisUnidades.png);
                }
            }
            .PR{
                background-image: url(../../assets/lateralmenu/ProgramacióndeRIego.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/ProgramacióndeRIego.png);
                }
            }
            .HA{
                background-image: url(../../assets/lateralmenu/HuelladeAgua.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/HuelladeAgua.png);
                }
            }
            .BIO{
                background-image: url(../../assets/lateralmenu/Biodiversidad.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/Biodiversidad.png);
                }
            }
            .infoClima{
                background-image: url(../../assets/lateralmenu/InfoClimatica.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/InfoClimatica.png);
                }
            }
            .consultasAvanzadas{
                background-image: url(../../assets/lateralmenu/Cosultasavanzadas.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/Cosultasavanzadas.png);
                }
            }
            .usuario{
                background-image: url(../../assets/lateralmenu/Miusuario.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/Miusuario.png);
                }
            }
            .closeicon{
                background-image: url(../../assets/lateralmenu/CerrarSesion.png);
                background-size: 20px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/CerrarSesion.png);
                }
            }
            .downloadicon{
                background-image: url(../../assets/lateralmenu/downloadicon.svg);
                background-size: 26px;
                &:hover{
                    background-image: url(../../assets/lateralmenu/downloadicon.svg);
                }
            }
        }
        .subNuevaBIO{
            padding: 0;
            li{
                padding: 0;
                margin: 5px 0;
            }
        }
    }
}

.action{
    &-new{
        background-color: green;
        color: white;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-delete{
        background-color: red;
        color: white;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.carousel{
    &-container{
        text-align: center;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        h2{
            font-size: 18px; 
        }
    }
    &-card{
            border-radius: 25px !important;
            display: flex;
            justify-content: center;
            background-color: #EDEDED !important;
            flex-direction: row;
            box-shadow: 0px 0px 10px 0px #EDEDED !important;
            width: 100%;
            position: relative;
            &-desktop{
                border-radius: 25px !important;
                display: flex;
                justify-content: center;
                background-color: #EDEDED !important;
                flex-direction: row;
                box-shadow: 0px 0px 10px 0px #EDEDED !important;
                margin-bottom: 25px;
                width: 100%;
                height: 100%;
                margin-top: 15px;
            }
    }
    &-arrow{
        &-left{
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
        &-rigth{
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
        
    }
    &-properties{
        display:flex;
        flex-direction: column;
        padding: 20px 35px;
        width: 100%;    
        p{
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            color: #949494;
            align-items: flex-start;
        }
        span{
            font-weight: 500;
            font-size: 18px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
            color: #000000;
        }
    }
}

.pagination{
    align-items: center;
    ul{
        li{
            button{
                font-size: 18px;
                font-weight: 600;
                background-color: #fff;
                color: #80C606;
                border-radius: 0;
                padding: 12px;
                box-sizing: content-box;
                border: 1px solid #80C606;
                margin: 0 0 0 -1px;
                &[aria-current=true]{
                    background-color: #80C606;
                    color: #fff;
                    &:hover{
                        background-color: #fff;
                        color: #80C606; 
                    }
                }
                &:hover{
                    background-color: #80C606;
                    color: #fff;
                }
            }
            &:first-child, &:last-child{
                button{
                    &[disabled]{
                        background-color: #f2f2f2;
                    }
                }
            }
        }
    }
}

.chart{
    &-container{
        background-color: #f2f2f2;
        border-radius: 42px;
        padding: 20px 40px;
        margin: 20px 0;
        ul{
            li{
                margin: 8px 0;
            }
        }
    }
    &-pos{
        &-center{
            text-align: center;
            margin: 0 auto;
            max-width: 500px;
        }
    }

    &-ris{
        position: relative;
        margin: 60px 0 40px 0;
        &-text{
            position: absolute;
            display: flex;
            justify-content: space-around;
            width: 100%;
            z-index: 1;
            top: -18px;
            font-size: 12px;
            font-weight: 500;
        }
        &-numeric{
            position: absolute;
            display: flex;
            z-index: 1;
            top: -24px;
            font-size: 16px;
            font-weight: 500;
            &.one{
                width: 33%;
                justify-content: flex-end;
            }
            &.two{
                width: 66%;
                justify-content: flex-end;
            }
            &.min{
                width: 0%;
            }
            &.max{
                width: 100%;
                justify-content: flex-end;
            }
        }
        &-box{
            width: 100%;
            height: 30px;
            background-color: #ddd;
            display: flex;
            justify-content: space-between;
            position: relative;
            background: linear-gradient(90deg, rgba(254,192,7,1) 0%, rgba(183,183,39,1) 25%, rgba(112,173,71,1) 50%, rgba(184,87,36,1) 75%, rgba(255,0,0,1) 100%);
        }
        p{
            margin: 0;
        }
    }

    &-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
    &-indice{
        width: 100%;
        position: relative;
        margin: 40px 0 40px 0;
        
        &-text{
            position: absolute;
            display: flex;
            justify-content: space-around;
            width: 100%;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
            font-weight: 500;
        }
        &-numeric{
            position: absolute;
            display: flex;
            z-index: 1;
            top: -24px;
            font-size: 16px;
            font-weight: 500;
            &.min{
                width: 0%;
            }
            &.max{
                width: 100%;
                justify-content: flex-end;
            }
        }
        &-box{
            width: 100%;
            height: 30px;
            background-color: #ddd;
            display: flex;
            justify-content: space-between;
            position: relative;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(85,132,54,1) 100%);
            &.inverted{
                background: linear-gradient(90deg, rgba(85, 132, 54, 1) 0%, rgba(255, 255, 255, 1) 100%);
            }
        }
        p{
            margin: 0;
        }
    }

    &-pointer{
        position: absolute;
        left: 50%;
        bottom: -16px;
        width: 0px;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 22px solid #000;
    }

    &-summary{
        width: 100%;
        border-collapse: collapse;
        &-box{
            overflow-x: auto;
            max-width: 100%;
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            white-space: nowrap;
        }
        &-row{
            background-color: #70ad47 !important;
            color: #fff;
        }
        &-column{
            background-color: #385723 !important;
            color: #fff;
            min-width: 140px;
            max-width: 140px;
            white-space: pre-line;
        }
        th, td {
            border: 1px solid #dddddd;
            text-align: center;
            padding: 8px;
        }
        th {
            background-color: #f2f2f2;
        }
        tbody{
            td {
                color: #000;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    &-striped{
        tr:nth-child(odd) {
            background-color: #f2f2f2;
        }
        tr:nth-child(even) {
            background-color: #ffffff;
        }
    }
}

.tabla{
    &-comunidad{
        width: 100%;
        border-collapse: collapse;
        &-box{
            overflow-x: auto;
            max-width: 100%;
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            white-space: nowrap;
        }   
        
        tbody{
            border-top: 1px solid #80C606;
            border-bottom: 1px solid #80C606;
            tr{
                td {
                    text-align: center;
                    padding: 8px;
                    border-bottom: 1px solid #80C606;
                }
                td:first-child{
                    width: 50%;
                }
                td:nth-child(2){
                    width: 30%;
                }
                td:last-child{
                    width: 20%;
                }
            }
            .edicion-nombre{
                display: flex;
                gap: 16px;
                align-items: center;
                justify-content: center;
            }
        }
        &-invalida{
            background-color: #ff9e8f;
        }
        &-modificada{
            background-color: #c2fa61;
        }
    }
    &-bio{
        width: 100%;
        border-collapse: collapse;
        &-box{
            overflow-x: auto;
            max-width: 100%;
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            white-space: nowrap;
        }
        &-container{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
        }
        thead{
            border: 1px solid #80C606;
            tr{
                background-color: #e2f0d9;
                vertical-align: middle;
            }
            th{
                padding: 20px 5%;
                font-size: 18px;
                color: #80C606;
                height: 50px;
                white-space: normal;
                word-wrap: break-word;
                button{
                    width: 100%;
                    max-width: 48px;
                }
                &:first-child{
                    width: 50%;
                }
                &.fc{
                    padding: 20px 10px;
                }
            }
        }
        tbody{
            border: 1px solid #80C606;
            tr{
                border: 1px solid #80C606;
            }
            td{
                padding: 10px;
                text-align: center;
                &.cellFlex{
                    display: flex;
                    align-items: center;
                }
            }
        }
        &-add{
            text-align: center;    
        }
    }
}

.detalles{
    display: flex;
    flex-direction: row;
    &-sector{
        &-secciones{
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            justify-content: flex-start;
            align-items: stretch;
            a{
                text-decoration: none;
                margin: 20px 0;
                flex: 1;
                height: 100%;
                max-width: 300px;
                &:last-child{
                    div{
                        margin-right: 0;
                    }
                }
            }
        }
        &-priego{
            padding: 20px 35px;
            height: 70px;
            background-image: url('../../assets/programacionriego.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-right: 4%;
            p{
                color: #fff;
                font-weight: 800;
                font-size: 16px;
            }
        }
        &-hagua{
            padding: 20px 35px;
            height: 70px;
            background-image: url('../../assets/huelladeagua.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-right: 4%;
            p{
                color: #fff;
                font-weight: 800;
                font-size: 16px;
            }
        }
        &-bio{
            padding: 20px 35px;
            height: 70px;
            background-image: url('../../assets/biodiversidad.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-right: 4%;
            p{
                color: #fff;
                font-weight: 800;
                font-size: 16px;
            }
        }
        &-evo{
            padding: 20px 35px;
            height: 70px;
            background-image: url('../../assets/evolucion.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-right: 4%;
            p{
                color: #fff;
                font-weight: 800;
                font-size: 16px;
            }
        }
        &-container{
            display: flex;
            flex-direction: column;
            margin-top: 25px;
        }
        &-par{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &-container{
                &-left{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 97%;
                    margin-right: 3%;
                    h2{
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                }
                &-right{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 97%;
                    margin-left: 3%;
                    h2{
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                }
                
            }
        }
    }
}

.t4b{
    &-spacebetween{
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }
    &-input{
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        &.contador{
            padding: 50px 0 75px 0;
            img{
                position: absolute;
                z-index: -1;
                top: -28px;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
    &-table{
        border: 2px solid #005A81;
        border-collapse: collapse;
        margin: 20px 0;
        width: 100%;
        td:nth-child(1){
            width: 20%;
            text-align: center;
        }
        td:nth-child(2){
            width: 50%;
            padding: 16px 2%;
        }
        td:nth-child(3){
            width: 30%;
            padding: 16px 2%;
        }
        .cell-image{
            color: #005A81;
            font-weight: 600;
            text-transform: capitalize;
            img{
                background-color: gainsboro;
                border-radius: 100%;
                width: 100px;
            }
        }
        &-body{
            td:nth-child(1){
                width: 30%;
                text-align: center;
                padding: 16px 2%;
            }
            td:nth-child(2){
                width: 40%;
                padding: 16px 2%;
            }
            td:nth-child(3){
                width: 30%;
                padding: 16px 2%;
            }
        }
        &-mantenimiento{
            td:nth-child(1){
                width: 30%;
                text-align: center;
                padding: 16px 2%;
            }
            td:nth-child(2){
                width: 30%;
                padding: 16px 2%;
            }
            td:nth-child(3){
                width: 20%;
                padding: 16px 2%;
            }
            td:nth-child(4){
                width: 20%;
                padding: 16px 2%;
            }
        }

    }

    &-biodiversidad{
        &-table{
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            color: #80C606;
            font-size: 18px;
            font-weight: 600;

            tbody{
                tr:first-child{
                    border-top: 1px solid #000;
                }
    
                tr:last-child{
                    border-bottom: 1px solid #000;
                }

                td{
                    padding: 8px;
                    text-align: center;
                    &:first-child{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100px;
                    }
                    div{
                        background-color: #e2f0d9;
                        display: flex;
                        justify-content: center;
                        height: 46px;
                        border-radius: 10px;
                        align-items: center;
                        cursor: pointer;
                        &.basic{
                            background-color: #d8edb2; 
                        }
                    }
                }

                th{
                    transform: rotate(-90deg);
                    height: 60px;
                    width: 10%;
                    padding: 20px 0;
                }
            }
        }

    }

    &-capacidad{
        &-box{
            margin-top: 60px;
        }
    }

    &-limpieza{
        &-box{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 60px 0 40px 0;
        }
        &-btn{
            background-color: #80C606;
            width: 100%;
            max-width: 48%;
            text-align: center;
            color: #fff;
            padding: 22px 5%;
        }
    }
}

.info{
    &-icon{
        background-color: #5b9bd5;
        border-radius: 100%;
        padding: 2px;
        width: 22px;
        height: 22px;
        color: #fff;
        cursor: pointer;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}

.modaltip{
    h3{
        margin-bottom: 40px;
    }
    b{
        color: #000;
    }
    &-icon{
        background-color: #80C606;
        border-radius: 100%;
        padding: 2px;
        width: 22px;
        height: 22px;
        color: #fff;
        cursor: pointer;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &.pink{
            background-color: #f8cbad;
        }
    }
    &-box{
        position: relative;
        margin-top: 50px;
        &-top{
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #98ba81;
            padding: 10px;
            color: #fff;
            text-align: center;
            min-width: 200px;
        }
        &-list{
            border: 1px solid;
            padding: 30px 20px 20px 40px;
        }
    }
    &-triangle{
        cursor: pointer;
    }
}

.editDotacion{
    display: flex !important; 
    gap: 10px;
    align-items: center !important;
    color: #80C606 !important;
    background-color: transparent !important;
    font-size: 20px !important;
    font-weight: 600;
    &:hover{
        text-decoration: underline !important;
    }
}

.evolucion{
    &-options{
        padding: 10px 20px;
        flex: 1;
        color: white;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        background-color: #80C606;
        border: 1px solid;
        &:hover{
            background-color: #385723;
        }
        &.selected{
            background-color: #385723;
        }
        &-container{
            display: flex;
        }
    }
    &-dates{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10%;
        margin: 30px 5% 0;
    }
}

.avanzado{
    &-dates{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10%;
    }
}


@media only screen and (min-width: 1400px){
    .footer{
        &-box{
            max-width: 1300px;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }

}


@media only screen and (max-width: 900px){
    .footer{
        &-imageContainer{
            max-width: 270px !important;
        }
    }
}


//MEDIA QUERIES PARA FORMATO MOVIL

@media only screen and (max-width: 1300px) {
    .tabla{
        &-bio{
            &-container{
                &.advanced{
                    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .header{
        &-out{
            &-nav{
                &-login{
                    margin-left: 5%;
                    margin-right: 5%;
                    max-width: none;
                }
            }
        }
    }

    .tabla{
        &-bio{
            &-container{
                grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
                &.advanced{
                    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
                }
            }
        }
    }

    .detalles{
        &-sector{
            &-secciones{
                grid-template-columns: repeat(2, minmax(0, 1fr));
                gap: 10px;
            }
            &-priego{
                margin-right: 0;
            }
            &-hagua{
                margin-right: 0;
            }
            &-bio{
                margin-right: 0;
            }
            &-par{
                flex-direction: column;
                &-container{
                    &-left{
                        width: 100%;
                        margin-right: 0;
                    }
                    &-right{
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .carousel{
        &-properties{
            padding: 20px 50px;
            p{
                text-align: left;
            }
        }
    }

    .t4b{
        &-spacebetween{
            flex-direction: column;
            gap: 0;
        }
    }

    .footer{
        &-imageContainer{
            flex-basis: 50% !important;
            display: flex;
            justify-content: center;  
            align-items: center;
        }
        &-imageWrapper{
            max-width: 200px;
        }
        &-box{
            flex-wrap: wrap !important;
            margin-bottom: 20px;
            justify-content: center;
        }
    }

}

@media only screen and (max-width: 560px) {
    .toolbar{
        height: auto;
        &-right{
            justify-content: flex-start;
            margin-top: 20px;
        }
        &-left{
            justify-content: space-between;
        }
    }
    .footer{
        &-imageContainer{
            max-height: 100px;
            display: flex;
            justify-content: center; 
        }
    }
}

@media only screen and (max-width: 450px) {

    .stepper{
        &-mv{
            display: flex !important;
            justify-content: center !important;
        }
        &-dsk{
            display: none !important;
        }
    }

}

//MEDIA QUERIES PARA FORMATO ESCRITORIO

@media only screen and (min-width: 768px) {
    .header{
        &-out{
            &-nav{
                max-width: 600px;
                margin: 20px auto;
            }
        }
    }

    .carousel{
        &-container{
            display: none;
        }
    }
}