#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main{
    margin: 0;
    display: flex;
}

.hide{
    display: none !important;
}

figure{
    margin: 0;
}

.noMargin{
    margin: 0;
}

.textAlignCenter{
    text-align: center;
}

.spaceBetween{
    justify-content: space-between !important;
}

.spaceCenter{
    justify-content: center !important;
}

.width100{
    width: 100%;
}

.relative{
    position: relative;
}

.dsk{
    display: block;
}

.mv{
    display: none;
}

.dskI{
    display: block !important;
}

.mvI{
    display: none !important;
}

.textUpperCase{
    text-transform: uppercase;
}

.flex-align-box{
    display: flex;
    justify-content: center;
    align-items: center;
}

//Para estructura div -> a
.button{
    font-size: 18px;
    border-radius: 10px;
    text-align: center;
    margin: 16px 0;
    cursor: pointer;
    a{
        display: block;
        padding: 16px;
        text-decoration: none;
    }
    &.white{
        border: 1px solid #80C606;
        background-color: white;
        a{
            color: #80C606;
        }
        &:hover{
            background-color: #80C606;
            a{
                color: white;
            }
        }
    }
    &.blue{
        border: 1px solid #1658F3;
        background-color: #1658F3;
        a{
            color: white;
        }
        &:hover{
            background-color: white;
            a{
                color: #1658F3;
            }
        }
    }
    &.green{
        border: 1px solid #80C606;
        background-color: #80C606;
        a{
            color: white;
        }
        &:hover{
            background-color: white;
            a{
                color: #80C606;
            }
        }
        .icon{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }
    &.red{
        border: 1px solid red;
        background-color: red;
        a{
            color: white;
        }
        &:hover{
            background-color: white;
            a{
                color: red;
            }
        }
    }
}

.btn{
    font-size: 18px;
    border-radius: 10px;
    text-align: center;
    margin: 16px 0;
    padding: 16px;
    width: 100%;
    cursor: pointer;
    &.white{
        border: 1px solid #80C606;
        background-color: white;
        color: #80C606;
        &:hover{
            background-color: #80C606;
            color: white;
        }
    }
    &.green{
        border: 1px solid #80C606;
        background-color: #80C606;
        color: white;
        &:disabled{
            cursor: not-allowed;
            background-color: #80C606;
            color: white;
            opacity: 0.65;
        }
        &:hover{
            background-color: white;
            color: #80C606;
        }
    }
    &.red{
        border: 1px solid red;
        background-color: red;
        color: white;
        &:hover{
            background-color: white;
            color: red;
        }
    }
    .add{
        vertical-align: bottom;
        margin-right: 6px;
    }
}

.dropdown-box{
    font-size: 18px;
    border-radius: 2px;
    text-align: center;
    margin: 16px 0;
    padding: 16px;
    width: 100%;
    cursor: pointer;
    &.green{
        border: 1px solid #80C606;
        background-color: #80C606;
        color: white;
        &:disabled{
            cursor: not-allowed;
            background-color: #548235;
            color: white;
            opacity: 0.65;
        }
        &:hover{
            background-color: #548235;
            color: white;
        }
        &.active{
            background-color: #548235;
            &:hover{
                background-color: #80C606;
            }
        }
    }
}

.no-red-label{
    label{
        &.Mui-error{
            color: rgba(0, 0, 0, 0.6);
        }
        &.Mui-focused{
            color: #1976d2;
        }
    }
}

.radio-green{
    .red-option{
        .MuiButtonBase-root{
            border: 1px solid #c55a11;
            &.Mui-checked{
                background-color: #8a0000;
                & + span{
                    color: #fff;
                }
            }
        }
        &.MuiFormControlLabel-root{
            .MuiFormControlLabel-label{
                color: #c55a11;
            }
        }
    }
    .MuiFormGroup-root{
        flex-direction: initial;
        justify-content: space-between;
    }
    &.radio-column{
        .MuiFormGroup-root{
            flex-direction: column;
            .radio-tip{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                .MuiFormControlLabel-root{
                    max-width: 90%;
                }
            }
        }
    }
    .MuiFormControlLabel-root{
        text-align: center;
        margin: 0;
        width: 100%;
        max-width: 48%;
        position: relative;
        margin: 10px 0;
        .MuiFormControlLabel-label{
            position: absolute;
            width: 100%;
            color: #80C606;
            font-weight: 600;
        }
        &.Mui-disabled{
            cursor: not-allowed;
            opacity: 0.65;
        }
    }
    .MuiCheckbox-root{
        svg{
            visibility: hidden;
        }
    }
    .MuiButtonBase-root{
        width: 100%;
        padding: 30px;
        background-color: #fff;
        border-radius: 0;
        border: 1px solid #80C606;
        &.Mui-checked{
            background-color: #80C606; 
            & + span{
                color: #fff;
            }
        }
        span{
            display: none;
        }
    }

    &.disabled-opacity{
        .MuiFormControlLabel-root{
                &.Mui-disabled{
                    opacity: 1;
                }
            
        }
    }
}

.b-container{
    display: flex;
    gap: 10px;
    justify-content: center;
    &-green{
        padding: 16px;
        border-radius: 16px;
        background-color: #80C606;
        color: white;
        border: 1px solid #80C606;
    }
    &-white{
        padding: 16px;
        border-radius: 16px;
        background-color: #bfbfbf;
        color: white;
        border: 1px solid #bfbfbf;
    }
}

.errorMsg{
    font-size: 14px;
    color: red;
    font-weight: 600;
}

.errorInput{
    position: absolute;
    background-color: #be4b49;
    color: #fff;
    font-size: 12px;
    padding: 6px;
    bottom: 32px;
    right: 0;
    z-index: 2;
    &:before{
        content: "";
        width: 18px;
        height: 18px;
        display: block;
        background-color: #be4b49;
        position: absolute;
        left: -9px;
        top: 4px;
        z-index: -2;
        transform: rotate(45deg);
    }
}


@media only screen and (max-width: 768px) {
    .dsk{
        display: none;
    }
    
    .mv{
        display: block;
    }

    .dskI{
        display: none !important;
    }
    
    .mvI{
        display: block !important;
    }

    .radio-green{
        .MuiFormGroup-root{
            flex-direction: column;
        }
        .MuiFormControlLabel-root{
            max-width: 100%;
        }
    }

}