//Estilos página Welcome

.welcome{
    &-container{
        margin-top: -200px;
        &-wc{
            margin-top: -140px;
        }
    }
    &-main{
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        background-color: white;
        position: relative;
    }
    &-image{
        width: 100%;
    }
    &-background{
        background-image: url("../../assets/headblue.png");
        background-size: cover;
        min-height: 390px;
        
    }
    &-logo{
        &-tic4bio{
            height: 150px;
        }
    }
    &-logos{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-left: 6%;
        margin-right: 6%;
        padding-top: 20px;
        img{
            width: auto;
        }
        &-top{
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            margin-bottom: 20px;
            max-width: 400px;
        }
        &-bottom{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-style: italic;
            color:#7A7A7A;
            font-weight: 400;
            font-family: "Roboto";

            &-images{
                display: flex;
                flex-direction: row;
                margin-top: 15px;
            }
            img{
                max-height: 140px;
            }
        }
    }
    &-text{
        margin-right: 5%;
        margin-left: 5%;
        text-align: center;
    }
    &-body{
        padding: 30px 5%;
        background-color: white;
        border-radius: 20px;
        border: 0.05px solid rgba(0, 0, 0, 0.26);
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 65px;
        h1{
            text-align: center;
        }
        h2{
            color: #828282;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: .175px;
            text-align: center;
        }
        &-buttons{
            overflow: hidden;
        }
    }
}

//Estilos página Login

.login{
    &-input{
        width: 100%;
        padding: 6px;
        line-height: 34px;
        border: none;
        border-bottom: 1px solid;
        outline: none;
        font-size: 18px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        box-sizing: border-box;
        &::placeholder{
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 18px;
        }
        &.password{
            padding-right: 50px;
        }
    }
    &-password{
        padding-right: 50px;
        &-eye{
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translate(0, -50%);
            cursor: pointer;
            background-color: transparent;
            border-radius: 50%;
            border: none;
            &:hover{
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
    &-link{
        &-pass{
            text-align: right;
            a{
                text-decoration: none;
                color: #80C606;
            }
        }
    }
    &-buttons{
        &-separator{
            display: flex;
            align-items: center;
        }
        &-line{
            width: 100%;
            height: 1px;
            background-color: #797979;
        }
        &-or{
            margin: 0 5px;
            color: #797979;
        }
    }
}

//Estilos pagina Registro

.register{
    &-input{
        width: 100%;
        padding: 6px;
        line-height: 34px;
        border: none;
        border-bottom: 1px solid;
        outline: none;
        font-size: 18px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        box-sizing: border-box;
        &::placeholder{
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 18px;
        }
        &.password{
            padding-right: 50px;
        }
        &-button{
            width: 100%;
            padding: 6px;
            line-height: 34px;
            border: none;
            outline: none;
            font-size: 18px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            box-sizing: border-box;
            &-submit{
                background-color: #1658F3 !important;
                color: #ffff;
            }
            &-login{
                width: 100%;
                padding: 6px;
                line-height: 34px;
                border: none;
                outline: none;
                font-size: 18px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                box-sizing: border-box;
                background-color: #ffff !important;
                color: #249DDA;
            }
        }
    }
    &-password{
        padding-right: 50px;
        &-eye{
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translate(0, -50%);
            cursor: pointer;
            background-color: transparent;
            border-radius: 50%;
            border: none;
            &:hover{
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
    &-line{
        margin-top: 25px;
        margin-bottom: 25px;
        border: 1px solid #797979;
    }
}

//Estilos recuperar contraseña

.recover{
    &-code{
        text-align: center;
        a{
            text-decoration: none;
            color: #828282;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}

//Estilos home

.tic4bio{
    &-home{
        margin-top: 25px;
        width: 88%;
        margin-left: 6%;
        margin-right: 6%;
        margin-bottom: 60px;
        &-title{
            font-size: 20px;
            margin: 30px 0;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            &.fincas, &.sectores, &-unidades{
                margin: 40px 0 30px 0;
            }
        }
        &-subtitle{
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
        }
        .button{
            font-size: 16px;
            font-weight: 600;
            margin: 30px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 300px;
            &.detalles-finca{
                background-color: #80C606;
                border: 1px solid #80C606;
                color: #fff;
                padding: 1rem;
                &:hover{
                    background-color: #fff;
                    color: #80C606;
                    border: 1px solid #80C606;
                }
            }
            .add{
                color: #fff;
                cursor: pointer;
                padding-right: 15px;
            }
            .consultas{
                color: #fff;
                cursor: pointer;
                padding-right: 15px;
            }
            a{
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
            &.green{
                &:hover{
                    .add{
                        color: #80C606;
                    }
                }
            }
            &.blue{
                &:hover{
                    .consultas{
                        color: #1658F3;
                    }
                }
            }
        }
        .tip{
            font-size: 12px;
            color: #7A7A7A;
        }
    }

    &-riego{
        &-create{
            margin-left: 0px;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
        &-time{
            margin: 42px 0;
            display: flex;
            gap: 10px;
            > div{
                min-width: 180px;
            }
            .MuiOutlinedInput-root fieldset{
                border-right: none;
                border-left: none;
                border-radius: 0;
            }
        }
        &-blackout{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.7);
            z-index: 1000;
        }
    }

    &-finca{
        cursor: pointer;
            border-radius: 25px;
            min-height: 130px;
            height: 130px;
            box-shadow: 0px 0px 10px 0px #797979;
            margin-bottom: 20px;
            border: 1px solid #797979;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .details{
                width: 90%;
                padding: 20px 40px;
                border-radius: 25px 0 0 25px;
                &:hover{
                    background-color: #F8F8F8;
                }
                &-provincia{
                    color: #80C606;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 20px;
                }
                &-nombre{
                    font-size: 18px;
                    font-weight: 600;
                }
                &-dotacion{
                    font-size: 16px;
                    font-weight: 600;
                    color: #828282;
                }
            }
            .delete{
                width: 10%;
                justify-content: center;
                align-items: center;
                padding: 20px;
                border-radius: 0 25px 25px 0;
                height: 100%;
                &:hover{
                    background-color:#ffc6c2;
                }
            }
            a{
                height: 100%;
                display: flex;
                text-decoration: none;
                box-sizing: border-box;
                p{
                    color: #000;
                    margin: 0;
                }
            }
    }
    &-seccion{
        cursor: pointer;
        border-radius: 20px;
        min-height: 130px;
        height: 130px;
        margin-bottom: 20px;
        border: 2px solid #80C606;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        img{
            flex: 0 0 auto; 
            width: 100px; 
            height: auto; 
        }
        h3{
            margin-right: 25px;
            font-size: 30px;
            font-weight: 500;
            align-items: center;
            display: flex;
            width: 200px;
            justify-content: center;
            text-align: center;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
    }
    &-sector{
        margin-top: 25px;
        width: -webkit-fill-available;
    }
    &-fincas, &-sectores, &-unidades{
        list-style-type: none;
        padding: 0;
        &-btns{
            flex-direction: row;
            justify-content: space-between;
            display: flex;
            gap: 5%;
            button{
                max-width: 300px;
            }
            &.right{
                justify-content: flex-end;
            }
        }
        &-nombreFinca{
            font-size: 35px;
            font-weight: 600;
        }
        &-localizacion{
            color: #80C606;
            font-size: 30px;
            font-weight: 400;
        }
        &.detalles-finca{
            margin-top: 25px;
            &-container{
                background-color: #EDEDED !important;
                padding: 20px 6%;
                border-radius: 20px;
                border: 1px solid black;
                margin-bottom: 50px;
                p{
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                    display: flex;
                    flex-direction: column;
                    color: #949494;
                    align-items: flex-start;
                }
                span{
                    font-weight: 500;
                    font-size: 18px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
                    color: #000000;
                }
            }
        }

        
        li.tic4bio-unidad{
            cursor: pointer;
            border-radius: 25px;
            min-height: 130px;
            height: 130px;
            box-shadow: 0px 0px 10px 0px #797979;
            margin-bottom: 20px;
            border: 1px solid #797979;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .details{
                width: 90%;
                padding: 20px 40px;
                border-radius: 25px 0 0 25px;
                &:hover{
                    background-color: #F8F8F8;
                }
                &-provincia{
                    color: #80C606;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 20px;
                }
                &-nombre{
                    font-size: 18px;
                    font-weight: 600;
                }
                &-dotacion{
                    font-size: 16px;
                    font-weight: 600;
                    color: #828282;
                }
            }
            .delete{
                width: 10%;
                justify-content: center;
                align-items: center;
                padding: 20px;
                border-radius: 0 25px 25px 0;
                height: 100%;
                &:hover{
                    background-color:#ffc6c2;
                }
            }
            a{
                height: 100%;
                display: flex;
                text-decoration: none;
                box-sizing: border-box;
                p{
                    color: #000;
                    margin: 0;
                }
            }
        }

        &-create{
            margin-top: 25px;
            margin-left: 6%;
            margin-right: 6%;
            width: 100%;
            margin-bottom: 60px;
        }
        li.tic4bio-sector{
            cursor: pointer;
            border-radius: 25px;
            min-height: 130px;
            height: 130px;
            box-shadow: 0px 0px 10px 0px #797979;
            margin-bottom: 20px;
            border: 1px solid #797979;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .details{
                width: 90%;
                padding: 20px 40px;
                border-radius: 25px 0 0 25px;
                display: flex;
                flex-direction: column;
                &.full{
                    width: 100%;
                    &:hover{
                        border-radius: 25px;
                    }
                }
                &:hover{
                    background-color: #F8F8F8;
                }
                &-tipoCultivo{
                    color: #80C606;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                &-nombre{
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                &-hRiegoAplicadas{
                    font-size: 16px;
                    font-weight: 600;
                    color: #828282;
                }
                &-limiteDotacion{
                    font-size: 16px;
                    font-weight: 600;
                    color: #828282;
                }
            }
            .delete{
                width: 10%;
                justify-content: center;
                align-items: center;
                padding: 20px;
                border-radius: 0 25px 25px 0;
                height: 100%;
                &:hover{
                    background-color:#ffc6c2;
                }
            }
            a{
                height: 100%;
                min-height: 130px;
                display: flex;
                text-decoration: none;
                box-sizing: border-box;
                p{
                    color: #000;
                    margin: 0;
                }
            }
        }
        &-empty{
            font-size: 14px;
            font-weight: 600;
            color: #949494;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
    }
    &-title{
        font-size: 20px;
    }
    &-subtitle{
        font-weight: 400;
    }
    &-programacion{
        &-list{
            padding: 0;
            list-style-type: none;
            width: 100%;
            li{
                border-radius: 25px;
                min-height: 120px;
                box-shadow: 0px 0px 10px 0px #80C606;
                margin-bottom: 20px;
                border: 2px solid #80C606;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 40px;
                .left{
                    width: 35%;
                    margin-right: 10%;
                    text-align: center;
                    position: relative;
                    p{
                        font-size: 36px;
                        color: #385723;
                        font-weight: bold;
                    }
                    .bg-can{
                        position: absolute;
                        height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: -1;
                        opacity: 0.5;
                    }
                }
                .right{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    p{
                        margin-top: 0;
                    }
                }

                .fecha{
                    color: #7f7f7f;
                    font-weight: bold;
                    font-style: italic;
                    margin-top: 0;
                }

                .data{
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    max-width: 260px;
                    p{
                        color: #7f7f7f;
                        font-weight: 500;
                    }
                    img{
                        width: 50px;
                        height: 50px;
                    }
                }

                .temperature{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    &-images{
                        margin-bottom: 10px;
                        display: flex;
                    }
                    p{
                        text-align: center;
                    }
                }

                .cloud{
                    text-align: center;
                }
            }
        }
        &-nombre{
            font-size: 18px;
            font-weight: 600;
            color: #949494;
            margin: 40px 0;
            span{
                color: #000;
                margin-left: 5px;
            }
        }
    }
    &-progressbar{
        width: 100%;
        height: 60px;
        background-color: #ddd;
        display: flex;
        justify-content: space-between;
        position: relative;
        border: 2px solid #80C606;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px #80C606;
        &-bg{
            height: 20px;
            padding: 20px 0;
            background-color: #1f4e79;
            position: relative;
            p{
                position: absolute;
                padding-left: 20px;
                padding-right: 0 !important;
            }
        }
        &-total{
            position: absolute;
            right: 0;
            z-index: 0;
            padding: 20px 0;
        }
        p{
            margin: 0;
            padding-right: 20px;
            color: #80C606;
            font-weight: bold;
        }
    }
    &-dotacion{
        p{
            font-size: 16px;
            font-weight: 600;
            color: #949494;
            span{
                color: #000;
                margin-left: 5px;
            }
            &:first-child{
                margin-top: 25px;
            }
        }
    }
    &-prediccion{
        width: 100%;
        &-table{
            width: 100%;
            border-collapse: collapse;
            th, td {
                border: 1px solid #dddddd;
                text-align: center;
                padding: 8px;
            }
            th {
                background-color: #f2f2f2;
            }
            tbody{
                td {
                    color: #828282;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
    &-huella{
        &-dates{
            margin-bottom: 20px;
            &-box{
                display: flex;
                gap: 5%;
            }
            .tip{
                font-size: 12px;
                color: #7A7A7A;
            }
        }
        &-section{
            padding: 36px 60px;
            margin-bottom: 46px;
            border-bottom: 2px solid;
            color: #80C606;
            font-size: 26px;
            font-weight: bold;
            text-align: center;
        }
        &-img{
            height: 160px;
            img{
                flex: 0 0 auto;
                width: 160px;
                height: auto;
                border-radius: 100%;
            }
        }
        &-name{
            font-size: 18px;
            font-weight: 600;
            color: #000 !important;
            margin-bottom: 20px !important;
        }
        &-detail{
            font-size: 16px;
            font-weight: 600;
            color: #828282 !important;
            margin-bottom: 6px !important;
        }
        &-back{
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 16px;
            font-weight: 600;
            background-color: transparent;
            border: 0;
            padding: 0;
            cursor: pointer;
            img{
                height: 24px;
            }
        }
        &-almazara{
            &-img{
                width: 80px;
                background-color: black;
                border-radius: 87px;
            }
        }
    }
    &-biodiversidad{
        &-img{
            height: 160px;
            img{
                flex: 0 0 auto;
                width: 160px;
                height: auto;
                border-radius: 100%;
            }
        }
    }
}

.detalles{
    &-sector{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    &-tipoCultivo{
        color: #949494 !important; 
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
        span{
            color: #80C606;
        }
    }
    &-nombre{
        font-size: 18px;
        font-weight: 600;
        color: #949494;
        span{
            color: #000;
            margin-left: 5px;
        }
    }

}

.inputs-next{
    display: flex;
    justify-content: space-between;
    div:first-child{
        margin-right: 2%;
    }
    div:last-child{
        margin-left: 2%;
    }
}

.quincena{
    &-info{
        margin-bottom: 20px;
        p{
            font-size: 18px;
            font-weight: 600;
            span{
                color: #777;
            }
        }
    }
    &-autocomplete{
        max-width: 400px;
        margin-bottom: 20px;
    }
    &-list{
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        list-style-type: none;
        padding: 0;
    }
    &-item{
        cursor: pointer;
        border-radius: 25px;
        min-height: 130px;
        box-shadow: 0px 0px 10px 0px #797979;
        margin-bottom: 20px;
        border: 1px solid #797979;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 47.5%;
        &:nth-child(2n+1){
            margin-right: 5%;
        }
    }
    &-link{
        width: 100%;
        padding: 20px 40px;
        height: 100%;
        min-height: 130px;
        text-decoration: none;
        box-sizing: border-box;
        border-radius: 25px;
        &:hover{
            background-color: #F8F8F8;
        }
    }
    &-dia{
        font-weight: 600;
        color: #777 !important;
        font-size: 18px;
        margin: 0 0 8px 0;
        span{
            color: #000;
        }
        &-val{
            color: #80C606 !important;
        }
        &:first-child{
            color: #000 !important;
        }
    }
}

.enlace{
    color: #000;
    text-decoration: none;
}

.backoffice{
    &-login{
        &-title{
            color: #777 !important;
            margin: 50px 150px;
            text-align:center;
            font-size: 25px;
        }
    }
}

//MEDIA QUERIES PARA FORMATO MOVIL

@media only screen and (max-width: 900px) {
    .welcome{
        &-logos{
            &-bottom{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-style: italic;
                color:#7A7A7A;
                font-weight: 400;
                font-family: "Roboto";
    
                &-images{
                    display: flex;
                    flex-direction: row;
                    margin-top: 15px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .welcome{
        &-text{
           display: none;
        }
        &-container{
            margin-top: -100px;
            &-wc{
                margin-top: -60px;
            }
        }
        &-logos{
            &-bottom{
                img{
                    max-height: 120px;
                }
            }
        }
    }
    .tic4bio{
        &-sectores{
            &-btns{
                flex-direction: column-reverse !important;
                align-items: center;
                button{
                    max-width: none;
                }
            }
        }
        &-programacion{
            &-list{
                li{
                    padding: 20px;
                    .left{
                        width: 40%;
                        margin-right: 5%;
                    }
                    .right{
                        width: 60%;
                    }
                }
            }
        }
        &-prediccion{
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            overflow: auto;
            white-space: nowrap;
        }
        &-biodiversidad{
            &-img{
                img{
                    width: 120px;
                    height: 120px;
                }
                h3{
                    font-size: 24px;
                }
            }
        }
    }
    .quincena{
        &-autocomplete{
            max-width: 100%;
        }
        &-item{
            width: 100%;
            &:nth-child(2n+1){
                margin-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .welcome{
        &-logos{
            flex-direction: column;
            &-bottom{
                img{
                    max-height: 90px;
                    width: auto;
                }
            }
        }
        &-logo{
            &-tic4bio{
                height: 120px;
            }
        }
    }

    .tic4bio{
        &-huella{
            &-dates{
                flex-direction: column;
            }
        }
    }
}


//MEDIA QUERIES PARA FORMATO ESCRITORIO

@media only screen and (min-width: 1400px){
    .welcome{
        &-logos{
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
        &-text{
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media only screen and (min-width: 768px) {
    .welcome{
        &-body{
        max-width: 600px;
        margin: auto;
        margin-bottom: 65px;
        box-sizing: border-box;
        }
        &-text{
            h2{
                color: #434343;
                font-size: 20px;
                font-weight: 800;
                letter-spacing: 0.175px;
            }
        }
        &-background{
            min-height: 500px;
        }
        &-logos{
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 35px;
        }
        &-logo{
            &-tic4bio{
                height: 200px;
            }
        }

    }    
}

